<template>
  <signals-feed uri="crypto-signals" :defaultSortParams="{column: 'in_feed_from'}">
    <template #sorting="{sortBy, column, direction}">
      <ul class="filtering">
        <li :class="[
          { 'active': column === 'in_feed_from' },
          column === 'in_feed_from' ? (direction === 'asc' ? 'sort_asc' : 'sort_desc') : ''
        ]">
          <a href="#" @click="sortBy('in_feed_from', direction)">By Date</a>
        </li>
        <li :class="[
          { 'active': column === 'signal_strength' },
          column === 'signal_strength' ? (direction === 'asc' ? 'sort_asc' : 'sort_desc') : ''
        ]">
          <a href="#" @click="sortBy('signal_strength', direction)">By Strength</a>
        </li>
      </ul>
    </template>
    <template #data="{data}">
      <ul>
        <li v-for="account in data" :key="account.twitter_id" v-memo="[data]">
          <a :href="'https://twitter.com/'+account.screen_name" target="_blank">
            <div class="tweet-cont">
              <div class="tweet_pic"><img :src="account.profile_url" alt="twitter"></div>
              <div class="tweet-box">
                <div class="tweet_info">
                  <p class="tweet_name"><span>@{{ account.screen_name}}</span></p>
                  <p class="tweet_time">{{ account.in_feed_from }}</p>
                </div><!-- /.tweet-info -->
                <p class="tweet_content">{{ account.description }}</p>
                <div class="signal-str-alt">
                  <h3>Signal strength</h3>  
                  <svg width="100%" height="8" viewBox="0 0 100% 8">
                    <rect height="8" width="100%" rx="4" ry="4" fill="#EAEAEA"/>
                    <rect height="8" width="80%" rx="4" ry="4" fill="#0A81A7"/>
                  </svg>
                  <span class="rect-chart-info">80%</span>
                </div>
              </div><!-- /.tweet-box -->
            </div>
            <div class="signal-str">
              <h3>Signal strength</h3>
              <svg class="circle-chart" width="100" height="100" viewBox="0 0 120 120">
                <circle class="circle-chart__background" cx="60" cy="60" r="54" fill="none" stroke="#EAEAEA" stroke-width="8" />
                <circle class="circle-chart__circle" cx="60" cy="60" r="54" fill="none" stroke="#0A81A7" stroke-width="8" pathLength="100" stroke-linecap="round" :stroke-dasharray="account.signal_strength + 0.100" />
                <g class="circle-chart__info">
                  <text class="circle-chart__percent" x="60" y="59" alignment-baseline="central" text-anchor="middle" font-size="24" fill="#b0bec7">{{ account.signal_strength }}%</text>
                </g>
              </svg>
            </div>
          </a>
        </li>
      </ul>
    </template>
  </signals-feed>
</template>

<script>
import SignalsFeed from "@/components/SignalsFeed";

export default {
  name: 'CryptoSignals',
  components: {SignalsFeed},
};
</script>
<style scoped>
.circle-chart__circle {
  animation: circle-chart-fill 1s reverse;
  transform: rotate(-90deg);
  transform-origin: center;
}

.circle-chart__info {
  animation: circle-chart-appear 1s forwards;
  opacity: 0;
  transform: translateY(0.3em);
}

@keyframes circle-chart-fill {
  to { stroke-dasharray: 0 100; }
}

@keyframes circle-chart-appear {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>