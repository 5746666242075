import { createApp } from "vue";
import mitt from "mitt";
import router from "./routes/router";
import App from "./views/App";
import store from "@/store";
import CustomerApp from "@/views/layouts/CustomerApp";
import SharedApp from "@/views/layouts/SharedApp";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

let app = createApp(App);

app.config.globalProperties = {
    emitter: mitt(),
    toaster: toast,
};

app.component('customer-app-layout', CustomerApp);
app.component('shared-app-layout', SharedApp);

app
    .use(router)
    .use(store)
    .mount("#app");