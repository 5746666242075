<template>
  <me-component ref="user">
    <template #data="{data}">
      <section class="c-pannel">
        <h2>Edit profile</h2>
        <form action="#" method="post">
          <div class="ctrl-holder profile-photo">
            <user-avatar ref="drawing" as="canvas" :avatar="data.avatar" :regenerate="true"/>
            <span class="username" v-if="!showUsername">
              {{ editData.username ? editData.username : data.username }}
              <a href="#" v-if="data.role !== adminRole" @click="edinName">
                <svg height="1em" viewBox="0 0 512 512">
                  <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"></path>
                </svg>
              </a>
            </span>
          </div>
          <div class="ctrl-holder" v-if="showUsername">
            <label for="email">Update username:</label>
            <input type="text" :placeholder="data.username" :class="{ 'border-red-failed': editFailed.username }" v-model="editData.username">
            <em v-if="editFailed.username">{{ editFailed.username }}</em>
          </div>
          <div class="ctrl-holder">
            <label for="email">Update email:</label>
            <input type="email" :placeholder="data.email" :class="{ 'border-red-failed': editFailed.email }" v-model="editData.email">
            <em v-if="editFailed.email">{{ editFailed.email }}</em>
          </div>
          <password-toggle :label-info="{type: 'password_current', name: 'Current Password:'}" v-model:pwd-val="editData.password_current" :pwd-err="editFailed.password_current"/>
          <password-toggle :label-info="{type: 'password', name: 'New password:'}" v-model:pwd-val="editData.password" :pwd-err="editFailed.password"/>
          <password-toggle :label-info="{type: 'password_confirm', name: 'Confirm new password:'}" v-model:pwd-val="editData.password_confirmation" :pwd-err="editFailed.password"/>
          <div class="button-holder">
            <input type="button" value="Submit" @click="submit" :disabled="submitted">
          </div>
        </form>
      </section>
    </template>
  </me-component>
</template>

<script>
import MeComponent from "@/components/MeComponent";
import UserAvatar from "@/components/UserAvatar";
import PasswordToggle from "@/components/fields/PasswordToggle";
import httpAxios from "@/utils/httpAxios";
import cryptoData from "@/utils/crypto";

export default {
  name: "EditMe",
  components: {MeComponent, UserAvatar, PasswordToggle},
  data() {
    return {
      adminRole: process.env.VUE_APP_ADMIN_ROLE,
      editData: {
        avatar: '',
        username: '',
        email: '',
        password: '',
        password_confirmation: '',
        password_current: '',
      },
      showUsername: false,
      submitted: false,
      editFailed: {
        username: '',
        email: '',
        password: '',
        password_current: '',
        message: '',
      },
    }
  },
  methods: {
    submit() {
      let self = this,
          regeneratedAvatar = this.$refs.drawing.regeneratedAvatar;

      self.submitted = true;
      self.editData.token = self.$refs.user.data.access_token;

      if (regeneratedAvatar)
        self.editData.avatar = regeneratedAvatar;

      // clear error
      Object.keys(this.editFailed).forEach(function (key) {
        self.editFailed[key] = '';
      });
      // ajax request
      httpAxios({
        url: process.env.VUE_APP_API_URL+'/user/edit',
        method: 'post',
        data: self.editData
      }).then(() => {
        // if email is changed
        if (self.editData.email !== '') {
          self.toaster.success('We send message to your new email address, please check your inbox.\n\nYou will be redirected to login page.', {
            position: 'top-right',
            autoClose: 10000,
          });
          // redirect and logout
          setTimeout(function () {
            self.$store.dispatch('logOut');
            self.$router.push({ name: 'Home', hash: '#signin' });
          }, 11000);
        }
        // email is not changes
        else {
          self.toaster.success('Success updated.', {position: 'top-right', autoClose: 3000});
          // if avatar changed, update them
          if (regeneratedAvatar) {
            let user = cryptoData.storage.getItem('loggedUser');
            user.avatar = regeneratedAvatar;
            cryptoData.storage.setItem('loggedUser', user);
          }
        }
        self.showUsername = false;
      }).catch(error => {
        self.editFailed = Object.assign({}, self.editFailed, error.userEdit);
        self.toaster.error(error.userEdit.message, {position: 'top-right', autoClose: 3000});
      });
      self.submitted = false;
    },
    // edit username
    edinName() {
      this.editData.username = this.editData.username ? this.editData.username : this.$refs.user.data.username;
      this.showUsername = true;
    },
  },
}
</script>
