<template>
  <div class="footer">
    <div class="wrapper">
      <ul>
        <li><router-link :to="{name: 'Home', hash: '#home'}">home</router-link></li>
        <li><router-link :to="{name: 'Home', hash: '#features'}">features</router-link></li>
        <li><router-link :to="{name: 'Home', hash: '#pricing'}">pricing</router-link></li>
        <li><router-link :to="{name: 'Home', hash: '#contac'}" @click="modal.setIsOpen(true, 'contact')">contact</router-link></li>
        <li><router-link :to="{name: 'Home', hash: '#signin'}" id="show" @click="modal.setIsOpen(true, 'signin')" v-if="!isUserLogged">sign in</router-link></li>
        <li><router-link :to="{name: 'Crypto Signals'}" id="show" v-if="isUserLogged">Signals</router-link></li>
      </ul>
    </div><!-- /.wrapper -->
  </div><!-- /.footer -->
</template>

<script>
import popup from "@/utils/popup";
import store from "@/store";

export default {
  name: "MainFooter",
  data() {
    return {
      modal: popup,
      isUserLogged: store.getters.getLoggedUser ?? false,
    }
  },
}
</script>

<style scoped>

</style>