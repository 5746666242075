<template>
  <over-lay id="change-password#token" title="Change Password">
    <template #form>
      <form action="#" method="post">
        <password-toggle v-model:pwd-val="loginData.password" :pwd-err="loginFailed.password" :label-info="{type: 'password', name: 'Password:'}"/>
        <password-toggle v-model:pwd-val="loginData.password_confirmation" :pwd-err="loginFailed.password" :label-info="{type: 'password_confirm', name: 'Password confirm:'}"/>
        <div class="ctrl-holder">
          <button type="button" v-on:click.prevent="submit()" :disabled="submitted">submit</button>
        </div>
      </form>
      <p><a href="#signin" @click="modal.setIsOpen(true, 'signin')">Sign in</a></p>
    </template>
  </over-lay>
</template>

<script>
import OverLay from "@/components/shared/popup/OverLay";
import PasswordToggle from "@/components/fields/PasswordToggle";
import popup from "@/utils/popup";
import httpAxios from "@/utils/httpAxios";

export default {
  name: "ChangePassword",
  components: {OverLay, PasswordToggle},
  data() {
    return {
      modal: popup,
      loginData: {
        password: '',
        password_confirmation: '',
      },
      submitted: false,
      loginFailed: {
        password: '',
        message: false
      },
    }
  },
  mounted() {
    let locationHash = location.hash.split('#'),
        token = null;

    if (locationHash[2])
      token = locationHash[2];
    if (!token)
      token = localStorage.getItem(process.env.VUE_APP_TOKEN_KEY);

    this.loginData.tToken = token;
  },
  methods:
  {
    submit() {
      let self = this;
      self.submitted = true;
      // ajax request
      httpAxios({
        url: process.env.VUE_APP_API_URL+'/user/account/update-password',
        method: 'post',
        data: self.loginData,
      }).then((response) => {
        localStorage.removeItem(process.env.VUE_APP_TOKEN_KEY);
        self.$store.commit('LOGGED_USER', response.data);
        self.$router.push({ name: 'Crypto Signals' });
      }).catch(error => {
        self.submitted = false;
        if (typeof error === 'string') {
          return this.emitter.emit('loginFailed', {'message': error});
        }
        this.loginFailed = error.login;
        this.emitter.emit('loginFailed', error.login);
      });
    },
  },
}
</script>

<style scoped>

</style>