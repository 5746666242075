<template>
  <me-component>
    <template #data="{data}">
      <pre>
        {{ data }}
      </pre>
    </template>
  </me-component>
</template>

<script>
import MeComponent from "@/components/MeComponent";
export default {
  name: "ShowMe",
  components: {MeComponent},
}
</script>

<style scoped>

</style>