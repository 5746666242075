import cryptoData from "@/utils/crypto";

const DISPLAY_LOADER = (state, display) => {
    state.displayLoader = display;
};

const CURRENT_THEME = (state, theme) => {
    localStorage.setItem('currentTheme', theme);
    state.currentTheme = theme;
};

const LOGGED_USER = (state, user) => {
    let now = new Date();
    let expiryDate = new Date();
    user = cryptoData.value.decrypt(user);
    user.expiryDate = expiryDate.setTime(now.getTime() + user.expires_in * 1000);
    cryptoData.storage.setItem('loggedUser', user)
    state.loggedUser = user;
};

const REMOVE_LOGGED_USER = (state) => {
    state.loggedUser = null;
    localStorage.removeItem('loggedUser');
};

export default {
    DISPLAY_LOADER,
    CURRENT_THEME,
    LOGGED_USER,
    REMOVE_LOGGED_USER,
};