import NotFound from "@/views/NotFound";

let nf404 = [
    {
        path: '/:pathMatch(.*)*',
        name: 'Not Found',
        meta: {
            layout: 'shared-app',
            guest: true,
        },
        component: NotFound
    }
];

export default nf404;