<template>
  <div class="user-box">
    <a class="username" data-id="3" v-if="user">
      {{ user.username }}
    </a>
    <a :class="['dark_toggle', {'active': theme === 'dark'}]" @click="switchTheme(theme === 'light'? 'dark' : 'light')">
      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.32031 11.6835C3.32031 16.6541 7.34975 20.6835 12.3203 20.6835C16.1075 20.6835 19.3483 18.3443 20.6768 15.032C19.6402 15.4486 18.5059 15.6834 17.3203 15.6834C12.3497 15.6834 8.32031 11.654 8.32031 6.68342C8.32031 5.50338 8.55165 4.36259 8.96453 3.32996C5.65605 4.66028 3.32031 7.89912 3.32031 11.6835Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </a>
    <a href="#" class="more">
      <svg viewBox="0 0 448 512">
        <path d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"/>
      </svg>
      <ul>
        <li>
          <router-link class="edit" :to="{name: 'Edit User'}">
            <svg height="1em" viewBox="0 0 512 512">
              <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/>
            </svg>
            <span>Edit profile </span>
          </router-link>
        </li>
        <li>
          <a class="logout" @click="logout">
            <svg width="20" height="15.24" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M26.6263 11.1981L17.6259 20.1986C16.8223 21.0022 15.4293 20.4397 15.4293 19.2878V14.1447H8.14326C7.43073 14.1447 6.85748 13.5715 6.85748 12.8589V7.71581C6.85748 7.00327 7.43073 6.43003 8.14326 6.43003H15.4293V1.28692C15.4293 0.140432 16.8169 -0.427453 17.6259 0.376158L26.6263 9.37661C27.1246 9.8802 27.1246 10.6945 26.6263 11.1981ZM10.2862 19.9307V17.7877C10.2862 17.4341 9.99692 17.1448 9.64334 17.1448H5.14311C4.19485 17.1448 3.42874 16.3787 3.42874 15.4305V5.14425C3.42874 4.19599 4.19485 3.42988 5.14311 3.42988H9.64334C9.99692 3.42988 10.2862 3.14058 10.2862 2.78699V0.644029C10.2862 0.29044 9.99692 0.00113944 9.64334 0.00113944H5.14311C2.30369 0.00113944 0 2.30483 0 5.14425V15.4305C0 18.2699 2.30369 20.5736 5.14311 20.5736H9.64334C9.99692 20.5736 10.2862 20.2843 10.2862 19.9307Z" fill="black"/>
            </svg>
            <span>Log out</span>
          </a>
        </li>
      </ul>
    </a>
    <!-- NOTIFICATIONS
      <a href="#" class="notification">
      <svg viewBox="0 0 448 512">
        <path d="M224 0c-17.7 0-32 14.3-32 32V49.9C119.5 61.4 64 124.2 64 200v33.4c0 45.4-15.5 89.5-43.8 124.9L5.3 377c-5.8 7.2-6.9 17.1-2.9 25.4S14.8 416 24 416H424c9.2 0 17.6-5.3 21.6-13.6s2.9-18.2-2.9-25.4l-14.9-18.6C399.5 322.9 384 278.8 384 233.4V200c0-75.8-55.5-138.6-128-150.1V32c0-17.7-14.3-32-32-32zm0 96h8c57.4 0 104 46.6 104 104v33.4c0 47.9 13.9 94.6 39.7 134.6H72.3C98.1 328 112 281.3 112 233.4V200c0-57.4 46.6-104 104-104h8zm64 352H224 160c0 17 6.7 33.3 18.7 45.3s28.3 18.7 45.3 18.7s33.3-6.7 45.3-18.7s18.7-28.3 18.7-45.3z"/>
      </svg>
      <span class="not-count">125</span>
    </a>
  -->
    
  </div><!-- /.user-box -->
</template>

<script>
export default {
  name: "UserMenu",
  data() {
    return {
      user: this.$store.getters.getLoggedUser,
      theme: this.$store.getters.getCurrentTheme,
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logOut');
      this.$router.push({ name: 'Home' });
    },
    switchTheme(theme) {
      this.theme = theme;
      this.$store.commit('CURRENT_THEME', theme);
      this.emitter.emit('theme', theme);
    },
  },
}
</script>