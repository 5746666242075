import cryptoData from "@/utils/crypto";

const getDisplayLoader = (state) => {
    return state.displayLoader;
};

const getCurrentTheme = (state) => {
    return state.currentTheme;
};

const getLoggedUser = (state) => {
    let user = state.loggedUser;
    if (typeof user !== 'object' && (user.startsWith('ey') || user.endsWith('=')))
        user = cryptoData.value.decrypt(user);
    return user;
};

export default {
    getDisplayLoader,
    getCurrentTheme,
    getLoggedUser,
};