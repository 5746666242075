<template>
  <over-lay id="signin" title="Sign in">
    <template #form>
      <form action="#" method="post">
        <div class="ctrl-holder">
          <label for="email">Email:</label>
          <input type="email" :class="{ 'border-red-failed': loginFailed.email }" v-model="loginData.email">
          <em v-if="loginFailed.email">{{ loginFailed.email }}</em>
        </div>
        <password-toggle :label-info="{type: 'password', name: 'Password:'}" v-model:pwd-val="loginData.password" :pwd-err="loginFailed.password"/>
        <div class="ctrl-holder">
          <button type="button" v-on:click.prevent="login()" :disabled="submitted">submit</button>
        </div>
        <p v-if="!loginFailed.nextStep">Don't have account? <a href="#signup" @click="modal.setIsOpen(true, 'signup')">Register here</a> and get one.</p>
        <p v-if="loginFailed.nextStep"><a href="#forgot" @click="modal.setIsOpen(true, 'forgot')">Reset password</a></p>
      </form>
    </template>
  </over-lay>
</template>

<script>
import popup from "@/utils/popup";
import httpAxios from "@/utils/httpAxios";
import OverLay from "@/components/shared/popup/OverLay";
import PasswordToggle from "@/components/fields/PasswordToggle";

export default {
  name: "SignIn",
  components: {OverLay, PasswordToggle},

  data() {
    return {
      modal: popup,
      loginData: {
        email: '',
        password: '',
      },
      loginFailed: {
        email: '',
        password: '',
        message: '',
        nextStep: ''
      },
      submitted: false,
    }
  },
  methods: {
    login() {
      let self = this;
      self.submitted = true;
      // ajax request
      httpAxios({
        url: process.env.VUE_APP_API_URL+'/user/login',
        method: 'post',
        data: self.loginData,
      }).then((response) => {
        self.$store.commit('LOGGED_USER', response.data);
        self.$router.push({ name: 'Crypto Signals' });
      }).catch(error => {
        self.submitted = false;
        this.loginFailed = error.login;
        this.emitter.emit('loginFailed', error.login);
      });
    },
  },
}
</script>
