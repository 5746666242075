<template>
  <div id="overlay" class="overlay" v-show="modal.isOpen">
    <span :id="spanId"></span>
    <div class="modal">
      <a href="#" @click="modal.setIsOpen(false)" id="close" class="close"></a>
      <!-- /.close -->
      <h4>{{ componentName }}</h4>
      <slot name="form"/>
      <div class="login-failed" v-if="loginFailed">
        <p>You have entered <em>{{ loginFailed.message }}</em>, </p>
        <p v-if="loginFailed.remaining">you have <em>{{ loginFailed.remaining }}</em> more attempts!</p>
      </div>
    </div><!-- /.modal -->
  </div><!-- /#overlay -->
</template>

<script>
import popup from "@/utils/popup";

export default {
  name: "OverLay",
  props: {
    id: String,
    title: String,
  },
  data() {
    return {
      modal: popup,
      componentName: this.title ?? null,
      spanId: this.id ?? null,
      loginFailed: this.error ?? null,
    }
  },
  mounted() {
    this.emitter.on('loginFailed', (error) => {
      this.loginFailed = error;
    });
  },
}
</script>

<style scoped>

</style>