<template>
  <header class="cie-index-section">
    <div class="wrapper">
      <img src="./../../assets/img/shared-logo.svg" alt="Crypto Insights">

      <nav>
        <router-link :to="{name: 'Home', hash: '#home'}">home</router-link>
        <router-link :to="{name: 'Home', hash: '#features'}">features</router-link>
        <router-link :to="{name: 'Home', hash: '#pricing'}">pricing</router-link>
        <router-link :to="{name: 'Home', hash: '#contact'}" @click="modal.setIsOpen(true, 'contact')">contact</router-link>
        <router-link :to="{name: 'Home', hash: '#signin'}" id="show" @click="modal.setIsOpen(true, 'signin')" v-if="!isUserLogged">sign in</router-link>
        <router-link :to="{name: 'Crypto Signals'}" id="show" v-if="isUserLogged">Signals</router-link>
      </nav>
    </div><!-- /.wrapper -->
  </header>
</template>

<script>
import popup from "@/utils/popup";
import store from "@/store";

export default {
  name: "MainHeader",
  data () {
    return {
      modal: popup,
      isUserLogged: store.getters.getLoggedUser ?? false,
    };
  },
  mounted() {
    if (this.isUserLogged)
      localStorage.removeItem(process.env.VUE_APP_TOKEN_KEY);
  },
}
</script>

<style scoped>

</style>