<template>
  <div id="sha-body">
    <pop-up/>
    <main id="home">
      <main-header/>
      <router-view></router-view>
      <main-footer/>
    </main>
  </div>
</template>

<script>
import MainHeader from "@/components/shared/MainHeader";
import MainFooter from "@/components/shared/MainFooter";
import PopUp from "@/components/shared/PopUp";

export default {
  name: 'SharedApp',
  components: {PopUp, MainHeader, MainFooter},
  data () {
    return {
      sectionObserver: null
    }
  },
  mounted () {
    this.observeSections()
  },
  methods: {
    observeSections() {
      try {
        this.sectionObserver.disconnect()
      } catch (error) {
        return false;
      }
      const options = {
        rootMargin: '0px 0px',
        threshold: 0
      }
      this.sectionObserver = new IntersectionObserver(this.sectionObserverHandler, options)
      // Observe each section
      const sections = document.querySelectorAll('.cie-index-section')
      sections.forEach(section => {
        this.sectionObserver.observe(section)
      })
    },
    sectionObserverHandler (entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id
          // Push sectionId to router here
          this.$router.push({ name: this.$route.name, hash: `#${sectionId}` })
        }
      }
    }
  }
}
</script>
<style lang="css" scoped>
@import '@/assets/css/reset.css';
</style>