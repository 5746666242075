<template>
  <div class="signal-type">
    <h2>Signal type:</h2>
    <ul>
      <li v-for="type in types" :key="type">
        <a href="#" @click="filter(type)" :class="{'active': active === type}">{{ type }}</a>
      </li>
    </ul>  
  </div>
</template>

<script>
export default {
  name: "SignalTypes",
  data() {
    return {
      types: [
          'All',
          'Metaverse',
          'DeFi',
          'L2',
          'Token',
      ],
      active: 'All',
      page: null,
    }
  },
  methods: {
    filter(type) {
      if (this.active === type)
        return;
      this.active = type;
      this.page = this.$route.name;
      this.emitter.emit('signal', { type: this.active });
    },
    resetFilter() {
      this.emitter.on('route', (data) => {
        if (this.page !== data.route.name)
          this.active = 'All';
      });
    },
  },
  mounted() {
    this.resetFilter();
  },
}
</script>
