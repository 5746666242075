<template>
  <img class="loading-logo-spinner" src="../assets/img/logo.svg" alt="logo"/>
</template>

<script>
export default {
  name: "LoaderInfinity"
}
</script>

<style scoped>
.loading-logo-spinner {
  margin-left: 35%;
  margin-top: 5%;
  margin-bottom: 10%;
  width: 35%;
  height: 35%;
  -webkit-animation:spin 5s linear infinite;
  -moz-animation:spin 5s linear infinite;
  animation:spin 5s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
</style>