// packages
import { createWebHistory, createRouter } from "vue-router";
import store from "@/store";
// other routes
import customers from "@/routes/customers";
import sharedPages from "@/routes/web";
import nf404 from "@/routes/404";

let routes = [].concat(
    sharedPages,
    customers,
    nf404
);

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact_active',
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth'
            }
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 }
        }
    },
});

// middlewares
router.beforeEach((to, from, next) => {
    // redirect to route
    let redirectToRoute = function (name) {
        if (name === from.name) {
            next();
            return;
        }
        next({ name: name });
    };
    // get logged user
    let loggedUser = store.getters.getLoggedUser;
    // check if access token expired
    if (loggedUser) {
        let currentDateTime = new Date().getTime();
        if (currentDateTime > loggedUser.expiryDate) {
            store.dispatch('logOut');
            return redirectToRoute('Home');
        }
    }

    // auth
    if (to.meta.auth) {
        if (loggedUser)
            return next();
        else
            return redirectToRoute('Home');
    }

    next();
});

export default router;