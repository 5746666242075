<template>
  <div class="page404">
    Page not found!
  </div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>
  .page404 {
    text-align: center;
    text-decoration: underline;
    font-size: 50px;
    padding: 25%;
  }
</style>