<template>
  <over-lay id="contact" title="Contact Us">
    <template #form>
      <form action="#" method="post">
        <div class="ctrl-holder">
          <label for="Subject">Subject:</label>
          <input type="text" :class="{ 'border-red-failed': contactFailed.subject }" v-model="contactData.subject">
          <em v-if="contactFailed.subject">{{ contactFailed.subject }}</em>
        </div>
        <div class="ctrl-holder" v-if="!isUserLogged">
          <label for="username">Username:</label>
          <input type="text" :class="{ 'border-red-failed': contactFailed.username }" v-model="contactData.username">
          <em v-if="contactFailed.username">{{ contactFailed.username }}</em>
        </div>
        <div class="ctrl-holder" v-if="!isUserLogged">
          <label for="email">Email:</label>
          <input type="email" :class="{ 'border-red-failed': contactFailed.email }" v-model="contactData.email">
          <em v-if="contactFailed.email">{{ contactFailed.email }}</em>
        </div>
        <div class="ctrl-holder">
          <label for="textarea">Content:</label>
          <textarea rows="10" cols="50" :class="{ 'border-red-failed': contactFailed.content }" v-model="contactData.content" @input="updateCounter"></textarea>
          <em>{{ characterCount }} characters remaining</em>
          <br/>
          <em v-if="contactFailed.content">{{ contactFailed.content }}</em>
        </div>
        <div class="ctrl-holder">
          <button type="button" v-on:click.prevent="submit()" :disabled="submitted">submit</button>
        </div>
      </form>
    </template>
  </over-lay>
</template>

<script>
import OverLay from "@/components/shared/popup/OverLay";
import popup from "@/utils/popup";
import store from "@/store";
import httpAxios from "@/utils/httpAxios";

export default {
  name: "ContactUs",
  components: {OverLay},
  data () {
    return {
      modal: popup,
      isUserLogged: store.getters.getLoggedUser ?? false,
      contactData: {
        subject: '',
        username: '',
        email: '',
        content: '',
      },
      contactFailed: {
        subject: '',
        username: '',
        email: '',
        content: '',
        message: ''
      },
      submitted: false,
      maxCharacters: 500,
    };
  },
  computed: {
    characterCount() {
      return this.maxCharacters - this.contactData.content.length;
    }
  },
  methods: {
    updateCounter() {
      if (this.contactData.content.length > this.maxCharacters) {
        this.contactData.content = this.contactData.content.slice(0, this.maxCharacters);
      }
    },
    submit() {
      let self = this;
      self.submitted = true;

      if (self.isUserLogged) {
        self.contactData.username = self.isUserLogged.username;
        self.contactData.email = self.isUserLogged.email;
      }

      // clear error
      Object.keys(this.contactFailed).forEach(function (key) {
        self.contactFailed[key] = '';
      });

      // ajax request
      httpAxios({
        url: process.env.VUE_APP_API_URL+'/user/contact-us',
        method: 'post',
        data: self.contactData,
      }).then(() => {
        self.success = true;
        self.toaster.success('An email has been sent.', {position: 'top-right', autoClose: 2000});
        setTimeout(function () {
          self.modal.setIsOpen(false);
        }, 3000);
      }).catch(error => {
        self.submitted = false;
        self.contactFailed = error.contactUs;
        self.toaster.error(error.contactUs.message, {position: 'top-right', autoClose: 3000});
      });
    },
  },
}
</script>

<style scoped>

</style>