import EarlySignals from "@/views/customered/EarlySignals";
import CryptoSignals from "@/views/customered/CryptoSignals";
import NftSignals from "@/views/customered/NftSignals";
import TwitterTrends from "@/views/customered/TwitterTrends";
import EditMe from "@/views/customered/user/EditMe";
import ShowMe from "@/views/customered/user/ShowMe";

let customers = [
    {
        path: '/early_signals',
        name: 'Early Signals',
        meta: {
            layout: 'customer-app',
            icon: 'target',
            nav: true,
            auth: true,
            infotip: 'Earliest hot projects, not reviewed by our team, DYOR.',
        },
        component: EarlySignals
    },
    {
        path: '/crypto_signals',
        name: 'Crypto Signals',
        meta: {
            layout: 'customer-app',
            icon: 'crypto',
            nav: true,
            auth: true,
            infotip: 'Early projects rated by both AI and our research team.',
        },
        component: CryptoSignals
    },
    {
        path: '/nft_signals',
        name: 'NFT Signals',
        meta: {
            layout: 'customer-app',
            icon: 'nft',
            nav: true,
            auth: true,
            infotip: 'Early NFT projects rated by both AI and our research team.',
        },
        component: NftSignals
    },
    {
        path: '/x_trends',
        name: 'X Trends',
        meta: {
            layout: 'customer-app',
            icon: 'trends',
            nav: true,
            auth: true,
            infotip: 'Twitter trends for early projects - see which project gained most traction during the specific timespan.',
        },
        component: TwitterTrends
    },
    {
        path: '/user/me/edit',
        name: 'Edit User',
        meta: {
            layout: 'customer-app',
            nav: false,
            auth: true,
        },
        component: EditMe
    },
    {
        path: '/user/me',
        name: 'Me',
        meta: {
            layout: 'customer-app',
            nav: false,
            auth: true,
        },
        component: ShowMe
    },
];

export default customers;