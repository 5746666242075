// import
import axios from "axios";
import store from "@/store";

// create
const service = axios.create({
    baseURL: '',
});

// token
if (store.getters.getLoggedUser) {
    service.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters.getLoggedUser.access_token;
}

// request interceptor
service.interceptors.request.use(
    (config) => {
        store.dispatch('displayLoader', true);
        return config;
    },
    (error) => {
        store.dispatch('displayLoader', false);
        return Promise.reject(error);
    },
);

// response interceptor
service.interceptors.response.use(
    (response) => {
        store.dispatch('displayLoader', false);

        return response;
    },
    (error) => {
        store.dispatch('displayLoader', false);

        let errors = error;

        if (error.response) {
            // session expired
            if (401 === error.response.status) {
                errors = error.response.data;
                store.dispatch('logOut');
            }

            // errors from backend
            if (error.response.status === 422) {
                errors = '';

                // for (let errorKey in error.response.data.errors) {
                //     for (let i = 0; i < error.response.data.errors[errorKey].length; i++) {
                //         errors += String(error.response.data.errors[errorKey][i]) + '<br>';
                //     }
                // }
                errors = error.response.data.errors;
            }

            // backend error
            if (500 === error.response.status) {
                errors = error.response.data.message;
            }

            // 404
            if (error.response.status === 404) {
                errors = 'Page not found';
            }
        }

        console.log(errors);
        return Promise.reject(errors);
    },
);

// export axios
export default service;