<template>
  <div id="cus-body" :class="{'dark_mode': theme === 'dark'}">
    <div class="wrapper">
      <aside>
        <div class="side-main">
          <header-component/>
          <user-menu/>
          <menu-component/>
          <signal-types/>
        </div>
        <footer-component/>
      </aside>
      <main>
        <router-view></router-view>
      </main>
    </div>
  </div>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent";
import UserMenu from "@/components/UserMenu";
import MenuComponent from "@/components/MenuComponent";
import SignalTypes from "@/components/SignalTypes";
import FooterComponent from "@/components/FooterComponent";

export default {
  name: "CustomerApp",
  components: {
    SignalTypes,
    MenuComponent,
    HeaderComponent,
    UserMenu,
    FooterComponent,
  },
  data() {
    return {
      theme: this.$store.getters.getCurrentTheme,
    }
  },
  methods: {
    themeToggle() {
      this.emitter.on('theme', (theme) => {
        this.theme = theme;
      });
    },
  },
  mounted() {
    this.themeToggle();
  },
}
</script>

<style lang="css" scoped>
@import '@/assets/css/style.css';
@import '@/assets/css/new-styles.css';
</style>