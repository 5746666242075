<template>
  <sign-in v-if="modal.name === 'signin'"/>
  <sign-up v-if="modal.name === 'signup'"/>
  <forgot-password v-if="modal.name === 'forgot'"/>
  <change-password v-if="modal.name === 'change-password'"/>
  <contact-us v-if="modal.name === 'contact'"/>
</template>

<script>
import popup from "@/utils/popup";
import ContactUs from "@/components/shared/popup/ContactUs";
import ChangePassword from "@/components/shared/popup/ChangePassword";
import ForgotPassword from "@/components/shared/popup/ForgotPassword";
import SignIn from "@/components/shared/popup/SignIn";
import SignUp from "@/components/shared/popup/SignUp";

export default {
  name: "PopUp",
  components: {ContactUs, ChangePassword, ForgotPassword, SignIn, SignUp},

  data() {
    return {
      modal: popup,
      modalName: null,
    };
  },
  mounted() {
    let locationHash = location.hash.split('#');

    if (!locationHash[1])
      return;

    this.setupModal(locationHash[1]);

    // put temporary token in local storage
    if (!locationHash[2])
      return;

    localStorage.setItem(process.env.VUE_APP_TOKEN_KEY, locationHash[2]);
  },
  methods: {
    setupModal(name) {
      this.modalName = name;
      this.modal.setIsOpen(true, name);
    },
  },
}
</script>

<style scoped>

</style>