<template>
  <over-lay id="signup" title="Sign up">
    <template #form>
      <div v-if="success">
        <p>Thank You!</p>
        <p>We sent a message to your the email.</p>
        <p>Please check email inbox to confirmation.</p>
      </div>
      <form action="#" method="post" v-if="!success">
        <div class="ctrl-holder">
          <label for="username">Username:</label>
          <input type="text" :class="{ 'border-red-failed': loginFailed.username }" v-model="loginData.username">
          <em v-if="loginFailed.username">{{ loginFailed.username }}</em>
        </div>
        <div class="ctrl-holder">
          <label for="email">Email:</label>
          <input type="email" :class="{ 'border-red-failed': loginFailed.email }" v-model="loginData.email">
          <em v-if="loginFailed.email">{{ loginFailed.email }}</em>
        </div>
        <password-toggle v-model:pwd-val="loginData.password" :pwd-err="loginFailed.password" :label-info="{type: 'password', name: 'Password:'}"/>
        <password-toggle v-model:pwd-val="loginData.password_confirmation" :pwd-err="loginFailed.password" :label-info="{type: 'password_confirmation', name: 'Password:'}"/>
        <div class="ctrl-holder">
          <button type="button" v-on:click.prevent="signup()" :disabled="submitted">submit</button>
        </div>
      </form>
      <p>Switch to <a href="#signin" @click="modal.setIsOpen(true, 'signin')">sign in</a>.</p>
    </template>
  </over-lay>
</template>

<script>
import popup from "@/utils/popup";
import httpAxios from "@/utils/httpAxios";
import OverLay from "@/components/shared/popup/OverLay";
import PasswordToggle from "@/components/fields/PasswordToggle";

export default {
  name: "SignUp",
  components: {OverLay, PasswordToggle},
  data() {
    return {
      modal: popup,
      loginData: {
        username: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
      submitted: false,
      success: false,
      loginFailed: {
        username: '',
        email: '',
        password: '',
        message: ''
      },
    }
  },
  methods: {
    signup() {
      let self = this;
      self.submitted = true;
      // ajax request
      httpAxios({
        url: process.env.VUE_APP_API_URL+'/user/register',
        method: 'post',
        data: self.loginData,
      }).then(() => {
        self.success = true;
      }).catch(error => {
        self.submitted = false;
        this.loginFailed = error.login;
        this.emitter.emit('loginFailed', error.login);
      });
    },
  },
}
</script>
