<template>
  <div class="content">
    <div class="wrapper">
      <div class="hero" id="features">
        <h1>Be the first to find hot new Crypto Projects</h1>
        <p>Leverage the knowledge of our AI powered research and get the most accurate crypto analytics.</p>

        <div class="illustration cie-index-section">
          <div class="hits">
            <p>200</p>
            <span>projects reviewed/day</span>
          </div><!-- /.hits -->
          <img src="./../../assets/img/illustration.png" alt="main illustration">
        </div><!-- /.illustration -->
      </div><!-- /.hero -->

      <div class="seller">

        <div class="seller-box odd">
          <div>
            <span>
              <h2>Early Crypto Signals</h2>
              <p>Easily discover early-stage crypto currencies worth investing in using our early crypto signals.</p>
            </span>
            <img src="./../../assets/img/crypto-signals.svg" alt="">
          </div>
        </div><!-- /.seller-box -->

        <div class="seller-box even">
          <div>
            <img src="./../../assets/img/nft-signals.svg" alt="">
            <span>
              <h2>NFT Signals</h2>
              <p>Get the latest info on the emerging NFT collections.</p>
            </span>
          </div>
        </div><!-- /.seller-box -->

        <div class="seller-box odd">
          <div>
            <img src="./../../assets/img/ai-human.svg" alt="">
            <span>
              <h2>Ai and Human Curated</h2>
              <p>Or team leverages AI to ensure the research we offer is as accurate as possible.</p>
            </span>
          </div>
        </div><!-- /.seller-box -->

        <div class="seller-box even">
          <div>
            <img src="./../../assets/img/cogs.svg" width="100px" alt="">
            <span>
              <h2>Discord and Telegram integration</h2>
              <p>Our tool offers ease of use and can be integrated with both Discord and Telegram.</p>
            </span>
          </div>
        </div><!-- /.seller-box -->

      </div><!-- /.seller -->

      <div class="pricing cie-index-section" id="pricing">
        <h3>Pricing</h3>

        <div class="pricing-wrapper">
          <div class="pricing-box">
            <h4>Starter</h4>
            <p>The Starter package is ideal for first-time researchers just getting into crypto ecosystem and looking to get basic tracking.</p>
            <span>Free</span>
            <ul>
              <li><span>Crypto Signals</span></li>
              <li>NFT Signals</li>
              <li>All the features (with delay)</li>
            </ul>
            <a href="#">Get Started</a>
          </div><!-- /.pricing-box -->

          <div class="pricing-box">
            <span class="pop">Most Popular</span>
            <div>
              <h4>Standard</h4>
              <p>The Standard package is best suited for a new researcher on a budget who is looking for basic tracking.</p>
              <span>Free</span>
              <ul>
                <li>Crypto Signals</li>
                <li>NFT Signals</li>
                <li>Real Time Results</li>
              </ul>
              <a href="#">Get Started</a>
            </div>
          </div><!-- /.pricing-box -->

          <div class="pricing-box">
            <h4>Professional</h4>
            <p>The professional package is tailored to the needs of a advanced researcher who is looking for maximum features, integration and advanced customization.</p>
            <span>Free</span>
            <ul>
              <li>Crypto Signals</li>
              <li>NFT Signals </li>
              <li>Real Time Results</li>
              <li>Add specific keywords</li>
              <li>Add profiles to track</li>
              <li>Discord Bot</li>
              <li>Telegram Bot</li>
            </ul>
            <a href="#">Get Started</a>
          </div><!-- /.pricing-box -->
        </div><!-- /.pricing-wrapper -->

      </div><!-- /.pricing -->

      <div class="seo">
        <h3>Discover The Latest Crypto Insights</h3>

        <p>Getting well researched data on new crypto currencies and NFT collections is hard. You often feel like you missed an opportunity or you found out about a great investment option too late in the game.</p>
        <p>This is one of the main reasons we created our tool. To help users get the crypto analaytics on time so they can make an educated decision about investing in new currencies or collections.The crypto currency realm, with its decentralization and innovation, offers unparalleled chances to not just grow financially, but also to be a part of revolutionary technological changes.</p>
        <p>What sets us apart from other available tools on the market is the human touch. Yes, we leverage the power of AI but we also have a team of dedicated researchers who manually verify data provided thus making it more accurate. Now, many of us have heard tales of early Bitcoin adopters who, with a mere few dollars, secured holdings that are now worth millions. While it's essential to proceed with caution and not every crypto currency will see such returns, early investment in promising projects has historically yielded incredible profits.</p>
        <p>And with the help of our tool and the insights it provides you will be able to decide where to invest more accurately thus increasing your chances of success.</p>
        <p>You will have no trouble understanding the value and potential benefits as our research is clearly presented and summarized in an easy to grasp signal-strength value. This lets you cut through the noise, dismiss those cases where it's too early to tell, or to late to act, and focus your attention only on the most promising opportunities.</p>
        <p>We fully understand how excising an exhilarating it is to be on the front lines. Beyond financial gains, there's an undeniable thrill in being among the pioneers in a transformative era. The early adopters of crypto aren't just passive spectators; they're active participants shaping the future of finance, governance, and countless other sectors. By jumping on opportunities early, you become a stakeholder in the course of the digital future and we are here to help you shape that future to best benefit your personal portfolio. It is as simple as that.</p>

        <p><a href="#">Sign up</a> and give our Crypto Insights a try!</p>
      </div><!-- /.seo -->

    </div><!-- /.wrapper -->
  </div><!-- /.content -->
</template>

<script>
export default {
  name: "HomePage"
}
</script>

<style scoped>

</style>