<template>
  <nav>
      <router-link
          v-for="route in routes"
          :key="route.name"
          :to="route.path"
          @click="emitRoute(route)"
          active-class="active"
          exact-active-class="exact_active">
        <img :src="require(`.././assets/img/navico/${route.meta.icon}.svg`)" :alt="route.name"/>
        {{ route.name}}
        <span>
          <svg height="12px" viewBox="0 0 512 512">
            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
          </svg>
          <i>{{ route.meta.infotip }}</i>
        </span>
      </router-link>
  </nav>
</template>

<script>
export default {
  name: "MenuComponent",
  data() {
    return {
      current: null,
    }
  },
  computed: {
    routes: function () {
      let pages = [],
          routes = this.$router.options.routes;
      for (let i in routes) {
        if (!routes[i].meta.nav)
          continue;
        pages.push(routes[i]);
      }
      return pages;
    }
  },
  methods: {
    emitRoute(route) {
      if (this.current === route.name)
        return;
      this.current = route.name;
      this.emitter.emit('route', { route: route });
    }
  },
}
</script>

<style scoped>

</style>