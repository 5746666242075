<template>
  <footer>
    <ul>
      <li><a href="#">Terms of Service</a></li>
      <li><a href="#">Privacy Policy</a></li>
      <li><a href="#">Cookie Policy</a></li>
      <li><a href="#">Accessibility</a></li>
      <li>&copy; {{ new Date().getFullYear() }} {{ appName }}.</li>
    </ul>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
    }
  },
}
</script>