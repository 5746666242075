import CryptoJS from 'crypto-js';

let secretKey = process.env.VUE_APP_ENCRYPTED_KEY;
let ivKey = process.env.VUE_APP_IV_KEY;

const cryptoData = {

    value:
    {
        data: null,

        get() {
            try {
                this.check();
            } catch (e) {
                return e;
            }
            return this.value;
        },

        set(data) {
            this.data = (typeof data === "object") ? JSON.stringify(data) : data;
            return this;
        },

        check() {
            if (!this.data) {
                throw new Error('You must first call "value.set" method!');
            }
        },

        isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },

        encrypt(value = null) {
            if (value)
                this.set(value);

            try {
                // check passed value existing
                this.check();
                // convert the key material to a WordArray, so that it's interpreted as key
                let secretKeyWa = CryptoJS.enc.Utf8.parse(secretKey);
                // convert the key material to a WordArray, so that it's interpreted as key
                secretKeyWa = secretKeyWa.concat(CryptoJS.enc.Base64.parse('\0'.repeat(32 - secretKeyWa.sigBytes)));
                // apply a 16 bytes zero IV (as WordArray)
                let ivKeyWa = CryptoJS.enc.Base64.parse(ivKey);
                // encrypt data
                this.data = CryptoJS.AES.encrypt(this.data, secretKeyWa, {iv: ivKeyWa});
                // base64 encode data
                this.data = btoa(JSON.stringify(
                    {iv: CryptoJS.enc.Base64.stringify(ivKeyWa), value: this.data.toString()}
                ));
            } catch (error) {
                return error;
            }

            return value ? this.data : this;
        },

        decrypt(value = null) {
            if (value)
                this.set(value);

            try {
                // check passed value existing
                this.check();
                // "value" need to "unserialize" it in JS at the end.
                // encrypted data has creates a JSON to store iv, value and a mac and base64 encodes it.
                // so let's base64 decode the string to get them.
                let encrypted  = JSON.parse(atob(this.data));
                // decrypt data
                let decrypted = CryptoJS.AES.decrypt(encrypted.value, CryptoJS.enc.Utf8.parse(secretKey), {
                    // IV is base64 encoded, expected as word array in cryptojs
                    iv: CryptoJS.enc.Base64.parse(encrypted.iv),
                    mode: CryptoJS.mode.CBC,
                });
                // convert to string
                decrypted = decrypted.toString(CryptoJS.enc.Utf8);
                this.data = null;
                return this.isJsonString(decrypted) ? JSON.parse(decrypted) : decrypted;
            } catch (error) {
                return error;
            }
        },
    },

    storage:
    {
        getItem: (storageKey) => {
            // get the store from local storage.
            let data = localStorage.getItem(storageKey);

            if (data) {
                try {
                    // decrypt the store retrieved from local storage
                    // using our encryption token.
                    return cryptoData.value.decrypt(data);
                } catch (e) {
                    console.log(e);
                    // the store will be reset if decryption fails.
                    this.removeItem(storageKey);
                }
            }
            return null;
        },

        setItem: (storageKey, value) => {
            // Encrypt the store using our encryption token.
            let data = cryptoData.value.encrypt(value);
            // Save the encrypted store in local storage.
            localStorage.setItem(storageKey, data);
            return data;
        },

        removeItem: (storageKey) => localStorage.removeItem(storageKey),
    },
};

export default cryptoData;