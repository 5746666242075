<template>
  <over-lay id="forgot" title="Forgot password">
    <template #form>
      <div v-if="success">
        <p>OK!</p>
        <p>We sent a message to your email.</p>
        <p>Please click on link inside there.</p>
      </div>
      <form action="#" method="post" v-if="!success">
        <div class="ctrl-holder">
          <label for="email">Email:</label>
          <input type="email" :class="{ 'border-red-failed': loginFailed.email }" v-model="loginData.email">
          <em v-if="loginFailed.email">{{ loginFailed.email }}</em>
        </div>
        <div class="ctrl-holder">
          <button type="button" v-on:click.prevent="submit()" :disabled="submitted">submit</button>
        </div>
      </form>
      <p>
        You can also
        <a href="#signin" @click="modal.setIsOpen(true, 'signin')">sign in</a>
        or
        <a href="#signup" @click="modal.setIsOpen(true, 'signup')">register</a>
        if you are not registered.
      </p>
    </template>
  </over-lay>
</template>

<script>
import OverLay from "@/components/shared/popup/OverLay";
import popup from "@/utils/popup";
import httpAxios from "@/utils/httpAxios";

export default {
  name: "ForgotPassword",
  components: {OverLay},
  data() {
    return {
      modal: popup,
      loginData: {email: null},
      loginFailed: {email: false},
      submitted: false,
      success: false,
    }
  },
  methods: {
    submit() {
      let self = this;
      self.submitted = true;
      // ajax request
      httpAxios({
        url: process.env.VUE_APP_API_URL+'/user/forgot',
        method: 'post',
        data: self.loginData,
      }).then(() => {
        self.success = true;
      }).catch(error => {
        self.submitted = false;
        this.loginFailed = error.login;
        this.emitter.emit('loginFailed', error.login);
      });
    },
  }
}
</script>

<style scoped>

</style>