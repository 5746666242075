<template>
  <ul>
    <li></li>
  </ul>
</template>

<script>
export default {
  name: "NftSignals"
}
</script>
