<template>
  <err-msg v-if="error"/>
  <loader-infinity v-if="loading"/>
  <slot v-if="data" name="sorting" v-bind="{sortBy, column, direction}" />
  <section class="feed">
    <slot v-if="data" :data="data" name="data" />
  </section>
  <p class="error" v-if="end">No more results.</p>
</template>

<script>
import store from "@/store";
import axios from "axios";
import ErrMsg from "@/components/ErrMsg";
import LoaderInfinity from "@/components/LoaderInfinity";

export default {
  name: "SignalsFeed",
  components: {ErrMsg, LoaderInfinity },
  props: {
    uri: {type: String, required: true},
    defaultSortParams: Object,
  },
  data() {
    return {
      data: [],
      page: 1,
      last: 0,
      end: false,
      error: false,
      loading: true,
      column: this.defaultSortParams.column ?? 'in_feed_from',
      direction: this.defaultSortParams.direction ?? 'desc',
      filter: null,
    }
  },
  methods: {
    async loadData() {
      let
          user = store.getters.getLoggedUser,
          post = {
            page: this.page,
            sorting: {
              column: this.column,
              direction: this.direction,
            },
            filtering: this.filter
          },
          config = {headers: { Authorization: `Bearer ${user.access_token}`}};

      this.loading = true;
      this.error = false;
      this.end = false;

      axios.post(
          process.env.VUE_APP_API_URL+'/'+this.uri,
          post,
          config
      ).then(async (response) => {
        if (response.data.data.length) {
          this.data.push(...response.data.data);
          this.last = response.data.last_page
          this.end = this.page === this.last ?? true;
          this.page++;
        } else {
          this.end = true;
        }
      }).catch(() => {
        this.error = true;
      }).finally(() => this.loading = false );
    },
    sortBy(column, direction) {
      if (column !== this.column)
        this.direction = 'desc';
      else if ((column === this.column) && (direction === 'asc'))
        this.direction = 'desc';
      else if ((column === this.column) && (direction === 'desc'))
        this.direction = 'asc';
      else
        this.direction = 'desc';

      this.column = column;
      this.page = 1;
      this.data = [];
      this.loadData();
    },
    infiniteScroll() {
      window.addEventListener('scroll', () => {
        // Distance from the top scrolled
        const scrollOffset = window.scrollY;
        // Length of the user's browser
        const browserViewHeight = window.innerHeight;
        // Height of the entire DOM
        const documentHeight = document.body.clientHeight
        const totalAmountScrolled = scrollOffset + browserViewHeight
        // Bottom of page reached.
        if (documentHeight - totalAmountScrolled <= 0) {
          // Load more content
          if (!this.end)
            this.loadData();
        }
      });
    },
    filterData() {
      this.emitter.on('signal', (data) => {
        this.filter = data.type;
        this.page = 1;
        this.data = [];
        this.loadData();
      });
    },
  },
  beforeMount() {
    this.loadData();
  },
  mounted() {
    this.infiniteScroll();
    this.filterData();
  },
}
</script>

<style scoped>

</style>