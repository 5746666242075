import HomePage from "@/views/shared/HomePage";

let sharedPages = [
    {
        path: '/',
        name: 'Home',
        meta: {
            layout: 'shared-app',
            guest: true,
        },
        component: HomePage
    },
];

export default sharedPages;