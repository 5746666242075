<template>
  <err-msg v-if="error"/>
  <loader-infinity v-if="loading"/>
  <slot v-if="data && !error && !loading" :data="data" name="data" />
</template>

<script>
import cryptoData from "@/utils/crypto";
import ErrMsg from "@/components/ErrMsg";
import LoaderInfinity from "@/components/LoaderInfinity";

export default {
  name: "MeComponent",
  components: { ErrMsg, LoaderInfinity },
  data() {
    return {
      data: null,
      error: false,
      loading: true,
    }
  },
  beforeMount() {
    this.me();
  },
  methods: {
    me() {
      this.loading = false;
      this.data = cryptoData.storage.getItem('loggedUser');
      if (!this.data)
        this.error = true;
      return true;
    },
  },
}
</script>
