const avatar =
{
    draw:
    {
        snippet: '#fff',

        setSnippetColor(hexColor)
        {
            let regCheck = /^#([0-9a-f]{3}){1,2}$/i;
            if (!regCheck.test(hexColor))
                throw new Error('Hex color is not valid string!');

            this.snippet = hexColor;
            return this;
        },

        svg(svg, matrix, length, background)
        {
            let wh = 200,
                snippetWH = wh/length,
                svgns = "http://www.w3.org/2000/svg",
                setAttrs = (e,a)=>Object.entries(a).forEach(([k,v])=>e.setAttribute(k,v));

            while (svg.hasChildNodes())
                svg.removeChild(svg.firstChild);

            setAttrs(svg, {
                width: wh,
                height: wh,
                viewBox: "0 0 " + wh + " " + wh
            });

            for (let j = 0; j < length; j++)
            {
                for (let i = 0; i < length; i++)
                {
                    let newRect = document.createElementNS(svgns, "rect");

                    setAttrs(newRect, {
                        x: i * snippetWH,
                        y: j * snippetWH,
                        width: snippetWH+1,
                        height: snippetWH+1,
                        fill: matrix[j].values[i] ? background : this.snippet
                    });

                    svg.appendChild(newRect);
                }
            }

            return svg;
        },

        canvas(canvas, matrix, length, background)
        {
            let ctx = canvas.getContext("2d"),
                rectWidth = Math.round(canvas.width / length),
                rectHeight = Math.round(canvas.height / length),
                currentY = 0;

            for (let j = 0; j < length; j++)
            {
                let currentX = 0;

                for (let i = 0; i < length; i++)
                {
                    ctx.fillStyle = matrix[j].values[i] ? background : this.snippet;
                    ctx.fillRect(currentX, currentY, currentX + rectWidth, currentY + rectHeight);
                    currentX += rectWidth;
                }

                currentY += rectHeight;
            }

            return canvas;
        },
    },

    render:
    {
        length: 0,
        color: null,
        context: [],

        data()
        {
            if (!this.color)
                this.color = this.randomColor();

            if (!this.length)
                this.length = this.randomLength();

            this.context = this.randomContextMatrix();

            let self = {
                length: this.length,
                color: this.color,
                context: this.context,
            };

            this.reset();
            return self;
        },

        setLength(min, max = 0)
        {
            if (min <= 4)
                throw new Error('Min value is to small, must be above of 4!');

            this.length = min;

            if (max) {
                try {
                    this.length = this.randomLength(min, max);
                } catch (error) {
                    throw new Error(error);
                }
            }

            return this;
        },

        setColor(hexColor)
        {
            let regCheck = /^#([0-9a-f]{3}){1,2}$/i;
            if (!regCheck.test(hexColor))
                throw new Error('Hex color is not valid string!');

            this.color = hexColor;
            return this;
        },

        randomLength(min = 7, max = 15)
        {
            if (min <= 4)
                throw new Error('Min value is to small, must be above of 4!');

            return Math.floor(Math.random() * (max - min + 1) ) + min;
        },

        randomColor()
        {
            let letters = '0123456789ABCDEF',
                color = '#';

            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }

            if (this.isBrightColor(color))
                return this.randomColor();

            return color;
        },

        randomContextMatrix()
        {
            let ctf = [],
                countZero = 0,
                countOne = 0,
                length = this.length;

            for (let i = 0; i < length; i++) {
                ctf[i] = {
                    values: [],
                    cf: 0.5
                };
            }

            ctf[0].cf = 0.95;
            ctf[1].cf = 0.9;
            ctf[2].cf = 0.8;
            ctf[ctf.length - 1].cf = 0.9;

            for (let i = 0; i < length; i++ ) {
                let x = 0;
                for (; x < length / 2; x++) {
                    let rnd = Math.random();
                    if ( rnd > ctf[i].cf ){
                        countZero++;
                        ctf[i].values[x] = 0;
                    } else {
                        countOne++;
                        ctf[i].values[x] = 1;
                    }
                }
                for (; x < length; x++) {
                    ctf[i].values[x] = ctf[i].values[length - 1 - x];
                }
            }

            let division = countOne / countZero;

            if (division === 1)
                console.warn('Attitude 1!');

            return ctf;
        },

        isBrightColor(color, threshold = 128)
        {
            // Parse the color string into RGB components
            let rgb = parseInt(color.slice(1), 16),
                r = (rgb >> 16) & 0xff,
                g = (rgb >> 8) & 0xff,
                b = rgb & 0xff,
                // Calculate brightness using the formula: (r * 299 + g * 587 + b * 114) / 1000
                brightness = (r * 299 + g * 587 + b * 114) / 1000;
            // Return true if the color is not bright (below the threshold), false otherwise
            return brightness < threshold;
        },

        reset()
        {
            this.length = 0;
            this.color = null;
            this.context = [];
        },
    },
};

export default avatar;