<template>
  <component :is="layout"/>
</template>

<script>
const default_layout = 'customer-app';

export default {
  name: 'App',
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout';
    }
  }
}
</script>
