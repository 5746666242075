<template>
  <div :class="className">
    <label :for="label.type">{{ label.name }}</label>
    <input :type="passwordField.type" :class="{ 'border-red-failed': pwdErr }" v-model="input.value" @input="emitInputValue">
    <a href="#" @click="toggle">
      <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!passwordField.show">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path d="M2 2L22 22" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
          <path d="M6.71277 6.7226C3.66479 8.79527 2 12 2 12C2 12 5.63636 19 12 19C14.0503 19 15.8174 18.2734 17.2711 17.2884M11 5.05822C11.3254 5.02013 11.6588 5 12 5C18.3636 5 22 12 22 12C22 12 21.3082 13.3317 20 14.8335" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
          <path d="M14 14.2362C13.4692 14.7112 12.7684 15.0001 12 15.0001C10.3431 15.0001 9 13.657 9 12.0001C9 11.1764 9.33193 10.4303 9.86932 9.88818" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </g>
      </svg>
      <svg width="16px" height="16px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="passwordField.show">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
          <path d="M1 12C1 12 5 20 12 20C19 20 23 12 23 12" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
          <circle cx="12" cy="12" r="3" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></circle>
        </g>
      </svg>
    </a>
    <em v-if="pwdErr">{{ pwdErr }}</em>
  </div>
</template>

<script>
export default {
  name: "PasswordToggle",
  props: {
    divClassName: String,
    labelInfo: {type: Object, required: true},
    pwdErr: {type: String, required: true},
    pwdVal: {type: String, required: true},
  },
  data() {
    return {
      className: this.divClassName ?? 'ctrl-holder',
      label: this.labelInfo,
      passwordField: {
        show: false,
        type:'password'
      },
      input: {value: this.pwdVal, error: this.pwdErr},
    }
  },
  methods: {
    toggle() {
      this.passwordField.show = this.passwordField.show !== true;
      this.passwordField.type = this.passwordField.type === 'password' ? 'text' : 'password';
      return false;
    },
    emitInputValue() {
      this.$emit('update:pwdVal', this.input.value);
    },
  },
  watch: {
    // Watch for changes in the prop and update the local input value accordingly
    pwdVal(newValue) {
      this.input.value = newValue;
    },
  },
}
</script>
