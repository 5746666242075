import { createStore } from "vuex";
import actions from "@/store/actions";
import getters from "@/store/getters";
import mutations from "@/store/mutations";

const state = {
    displayLoader: false,
    currentTheme: localStorage.getItem('currentTheme') || 'light',
    loggedUser: localStorage.getItem('loggedUser') || null,
};

export default createStore({
    state,
    actions,
    getters,
    mutations,
});