<template>
  <signals-feed uri="crypto-signals" :defaultSortParams="{column: 'in_feed_from'}">
    <template #sorting="{sortBy, column, direction}">
      <ul class="filtering">
        <li :class="[
          { 'active': column === 'in_feed_from' },
          column === 'in_feed_from' ? (direction === 'asc' ? 'sort_asc' : 'sort_desc') : ''
        ]">
          <a href="#" @click="sortBy('in_feed_from', direction)">By Date</a>
        </li>
        <li :class="[
          { 'active': column === 'followers_change__change24h' },
          column === 'followers_change__change24h' ? (direction === 'asc' ? 'sort_asc' : 'sort_desc') : ''
        ]">
          <a href="#" @click="sortBy('followers_change__change24h', direction)">By Change</a>
        </li>
      </ul>
    </template>
    <template #data="{data}">
      <ul>
        <li v-for="account in data" :key="account.twitter_id" v-memo="[data]">
          <a :href="'https://twitter.com/'+account.screen_name" target="_blank">
            <div class="tweet-cont">
              <div class="tweet_pic"><img :src="account.profile_url" alt="twitter"></div>
              <div class="tweet-box">
                <div class="tweet_info">
                  <p class="tweet_name"><span>@{{ account.screen_name}}</span></p>
                  <p class="tweet_time">{{ account.in_feed_from }}</p>
                </div><!-- /.tweet-info -->
                <p class="tweet_content">{{ account.description }}</p>
              </div><!-- /.tweet-box -->
            </div>
            <div class="signal-str">
              <h3>Change</h3>
              <span :class="account.followers_change.state">{{ account.followers_change.sign + account.followers_change.change24h }}%</span>
            </div>
          </a>
        </li>
      </ul>
    </template>
  </signals-feed>
</template>

<script>
import SignalsFeed from "@/components/SignalsFeed";

export default {
  name: "TwitterTrends",
  components: {SignalsFeed},
}
</script>

<style scoped>
.higher {
  color: #41e01f;
}

.lower {
  color: #c23e3d;
}
</style>